<template>
    <v-card class="modal-create">
        <v-form ref="form" v-model="valid">
            <v-card-title class="d-flex justify-center">
                <span class="text-h5 text-center mb-11">Смена пароля</span>
            </v-card-title>
            <v-card-text class="modal-create__form">
                <div class="close-dialog" @click="closeDialog">
                    <img :src="require('@/assets/img/common/close-dialog.svg')"/>
                </div>
                <v-col
                        cols="12"
                >
                    <v-text-field
                            v-model="oldPassword"
                            :append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showOldPassword ? 'text' : 'password'"
                            label="Старый пароль"
                            placeholder="Введите старый пароль"
                            @click:append="showOldPassword = !showOldPassword"
                            class="evi-text-field"
                            color="#44D370"
                    />
                </v-col>
                <v-col
                        cols="12"
                >
                    <v-text-field
                            v-model="newPassword"
                            :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showNewPassword ? 'text' : 'password'"
                            label="Новый пароль"
                            placeholder="Придумайте новый пароль"
                            @click:append="showNewPassword = !showNewPassword"
                            class="evi-text-field"
                            color="#44D370"
                            :rules="passwordRules"
                    />
                </v-col>
                <v-col
                        cols="12"
                >
                    <v-text-field
                            v-model="newRePassword"
                            :append-icon="showNewRePassword ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showNewRePassword ? 'text' : 'password'"
                            label="Повторите новый пароль"
                            placeholder="Повторите новый пароль"
                            @click:append="showNewRePassword = !showNewRePassword"
                            class="evi-text-field"
                            color="#44D370"
                            :rules="repeatPasswordRules"
                            ref="repeatPasswordField"
                    />
                </v-col>
            </v-card-text>
            <v-card-actions class="modal-create__actions">
                <v-btn class="evi-button-green evi-button-green--fill" @click="change">
                    Сменить пароль
                </v-btn>
            </v-card-actions>
        </v-form>
    </v-card>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        name: "ChangePasswordDialog",
        data() {
            return {
                oldPassword: '',
                showOldPassword: false,
                newPassword: '',
                showNewPassword: false,
                newRePassword: '',
                showNewRePassword: false,
                passwordIsExist: false,
                valid: true,


                passwordRules: [
                    (v) => !!v || 'Пароль не может быть пустым',
                    (v) => v.length >= 8 || 'Пароль должен быть минимум 8 знаков в длину',
                    (v) =>
                        (/[0-9]/.test(v) && /[a-z]/.test(v) && /[A-Z]/.test(v)) ||
                        'Пароль должен состоять из латинских заглавных и строчных букв, а также цифр',
                    () => !this.passwordIsExist || 'Пароль не должен совпадать с предыдущими паролями',
                ],
                repeatPasswordRules: [
                    (v) => !!v || 'Повторите пароль',
                    (v) =>
                        (/[0-9]/.test(v) && /[a-z]/.test(v) && /[A-Z]/.test(v)) ||
                        'Пароль должен состоять из латинских заглавных и строчных букв, а также цифр',
                    () => !this.passwordIsExist || 'Пароль не должен совпадать с предыдущими паролями',
                    (v) => v === this.newPassword || 'Пароли не совпадают',
                ],
            }
        },
        methods: {
            ...mapActions({
                changePassword: 'user/changePassword',
            }),
            validate() {
                return !!this.$refs.form.validate();
            },
            change() {
                if (this.validate()) {
                    this.changePassword({
                        "current_password": this.oldPassword,
                        "new_password": this.newPassword,
                        "new_password_confirmation": this.newRePassword
                    }).then((response) => {
                       if (response) {
                           this.closeDialog();
                       }
                    });
                }
            },
            closeDialog() {
                this.$emit('close');
                this.oldPassword = '';
                this.newPassword = '';
                this.newRePassword = '';
                this.showOldPassword = false;
                this.showNewPassword = false;
                this.showNewRePassword = false;
                this.$refs.form.resetValidation();
            }
        },
        watch: {
            newPassword(val) {
                if (this.passwordIsExist) {
                    this.passwordIsExist = false;
                }
                this.$refs.repeatPasswordField.validate();

                return val;
            },
            newRePassword(val) {
                if (this.passwordIsExist) {
                    this.passwordIsExist = false;
                }

                return val;
            },
        }
    }
</script>

<style scoped>

</style>
