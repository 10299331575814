import {mapActions, mapState} from "vuex";

export var UserAutocompleteRequestsMixin = {
    data: () => ({
        userSearch: "",
        curatorSearch: "",
        timerId: null,
        userPage: 0,
        userPerPage: 20,
        userLoading: false,
    }),
    computed: {
        ...mapState("users", ['users',]),
    },
    methods: {
        ...mapActions({
            getUsers: 'users/getUsersBy',
            addUsers: 'users/addUsersBy',
        }),
        getUsersBy (search) {
            this.userPage = 1;
            this.userLoading = true;
            let args = {
                per_page: this.userPerPage,
                page: this.userPage,
                'filter[is_active]': true,
            };
            if (search) {
                args['filter[name]'] = search;
            }
            this.getUsers(args).then(() => {
                this.userLoading = false;
            });
        },
        addUsersBy (search) {
            this.userPage++;
            this.userLoading = true;
            let args = {
                per_page: this.userPerPage,
                page: this.userPage,
                'filter[is_active]': true,
            };
            if (search) {
                args['filter[name]'] = search;
            }
            this.addUsers(args).then(() => {
                this.userLoading = false;
            });
        },
        loadUsers () {
            this.getUsersBy("");
        },
        userIntersect(entries, observer, isIntersecting) {
            if (isIntersecting) {
                this.addUsersBy(this.userSearch || this.curatorSearch);
            }
        },
        searchUser (val) {
            if (val) {
                this.userPage = 0;
                if (this.userTimerId)
                    clearTimeout(this.userTimerId);

                this.userTimerId = setTimeout(() => {
                    this.getUsersBy(val);
                }, 500);
            }
        },
    },
    mounted() {
        this.loadUsers();
    }
};
