import {mapActions} from "vuex";

export var SubdivisionAutocompleteRequestsMixin = {
    data: () => ({
        subdivisionSearch: "",
        timerId: null,
        page: 0,
        perPage: 20,
        subdivisionLoading: false,
    }),
    methods: {
        ...mapActions({
            getSubdivisions: 'users/getSubdivisionsBy',
            addSubdivisions: 'users/addSubdivisionsBy',
        }),
        getSubdivisionsBy (search) {
            this.page = 1;
            this.subdivisionLoading = true;
            let args = {
                per_page: this.perPage,
                page: this.page,
            };
            if (search) {
                args['filter[name]'] = search;
            }
            this.getSubdivisions(args).then(() => {
                this.subdivisionLoading = false;
            });
        },
        addSubdivisionsBy (search) {
            this.page++;
            this.subdivisionLoading = true;
            let args = {
                per_page: this.perPage,
                page: this.page,
            };
            if (search) {
                args['filter[name]'] = search;
            }
            this.addSubdivisions(args).then(() => {
                this.subdivisionLoading = false;
            });
        },
        loadSubdivisions () {
            this.getSubdivisionsBy("");
        },
        subdivisionIntersect(entries, observer, isIntersecting) {
            if (isIntersecting) {
                this.addSubdivisionsBy(this.subdivisionSearch);
            }
        },
        searchSubdivision (val) {
            if (val) {
                this.page = 0;
                if (this.timerId)
                    clearTimeout(this.timerId);

                this.timerId = setTimeout(() => {
                    this.getSubdivisionsBy(val);
                }, 500);
            }
        },
    },
};
