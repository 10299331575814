<template>
  <div class="user-page">
    <div class="item-page__header" v-if="loading">
      <v-skeleton-loader
          type="list-item-avatar-three-line"
          width="50%"
          height="100%"
      />
    </div>
    <div class="item-page__body" v-if="loading">
      <v-skeleton-loader
          type="list-item, list-item, list-item, list-item"
          width="50%"
          height="100%"
      />
      <v-skeleton-loader
          type="list-item-avatar, list-item-avatar"
          width="50%"
          height="100%"
      />
    </div>
    <div class="user-page__header" v-if="!loading">
      <div class="user-page__name">
        <v-avatar
            color="#FFFFFF"
            size="115"
            class="user-page__avatar mr-5"
        >
          <img
              :src="profile.avatar_url || require('@/assets/img/users/profile-avatar.png')"
              alt=""
          >
        </v-avatar>
        <div>
          <v-text-field
              class="evi-text-field"
              color="#44D370"
              v-model="profile.name"
              disabled
          >
            <template v-slot:label>
              Имя
            </template>
          </v-text-field>
          <div class="user-page__actions ml-2 mt-1">
            <v-btn text class="evi-button-text" @click="avatarChangeModal = true">
              <img class="mr-2" :src="require('@/assets/img/profile/edit-photo.svg')" alt="">
              Обновить аватар
            </v-btn>
            <v-btn text class="evi-button-text" v-if="profile.is_main" @click="mainUserChangeModal = true">
              <img class="mr-2" :src="require('@/assets/img/common/main-user.svg')" alt="">
              Передать права владельца
            </v-btn>
            <v-btn text class="evi-button-text" @click="passwordChangeModal = true">
              <img class="mr-2" :src="require('@/assets/img/profile/lock.svg')" alt="">
              Сменить пароль
            </v-btn>
            <v-btn text class="evi-button-text" @click="openUserAnalytics(profile.id)"
                   v-if="hasRight('analytics.usersOwn') || hasRight('analytics.users')">
              <img class="mr-2" :src="require('@/assets/img/menu/analytics.svg')" alt="">
              Посмотреть аналитику
            </v-btn>
            <v-btn text class="evi-button-text" @click="logOut()">
              <img class="mr-2" :src="require('@/assets/img/profile/exit.svg')" alt="">
              Выйти
            </v-btn>
          </div>
        </div>
      </div>
      <div class="user-page__close" @click="$router.go(-1)">
        <v-icon size="20">mdi-close</v-icon>
      </div>
    </div>
    <div class="user-page__body" v-if="!loading">
      <div class="user-page__positions">
        <v-text-field
            v-model="name"
            label="Имя"
            class="evi-text-field mb-11"
            color="#44D370"
            @change="editProfilePage"
        />
        <v-autocomplete
            class="evi-autocomplete mb-11"
            color="#44D370"
            v-model="role"
            :items="localRoles"
            item-text="name"
            item-value="id"
            @change="editUserPage"
            :disabled="!hasRight('users.manage') || profile.is_main"
            clearable
            item-color="green"
        >
          <template v-slot:label>
            Роль
          </template>
          <template v-slot:append>
                    <span class="evi-select__arrow">
                        <v-icon>mdi-chevron-down</v-icon>
                    </span>
          </template>
          <template v-slot:selection="{item}">
            <div class="user-page__roles-select">
              <span class="color" :style="{backgroundColor: `${item.color}`}"/>
              {{ item.name }}
            </div>
          </template>
          <template v-slot:item="{item}">
            <div class="user-page__roles-select">
              <span class="color mt-1" :style="{backgroundColor: `${item.color}`}"/>
              {{ item.name }}
            </div>
          </template>
        </v-autocomplete>
        <v-autocomplete
            class="evi-autocomplete mb-11"
            color="#44D370"
            v-model="subdivision"
            :items="localSubdivisions"
            item-text="name"
            item-value="id"
            @change="editUserPage"
            :disabled="!hasRight('users.manage')"
            :loading="subdivisionLoading"
            @focus="loadSubdivisions"
            :search-input.sync="subdivisionSearch"
            @input.native="searchSubdivision(subdivisionSearch)"
            clearable
            item-color="green"
        >
          <template v-slot:label>
            Подразделение
          </template>
          <template v-slot:append-item>
            <div v-intersect="subdivisionIntersect"/>
          </template>
          <template v-slot:append>
                    <span class="evi-select__arrow">
                        <v-icon>mdi-chevron-down</v-icon>
                    </span>
          </template>
        </v-autocomplete>
        <v-text-field
            v-model="position"
            label="Должность"
            class="evi-text-field mb-11"
            color="#44D370"
            @change="editUserPage"
            :disabled="!hasRight('users.manage')"
        />
        <v-text-field
            v-model="email"
            label="Email"
            class="evi-text-field mb-11"
            color="#44D370"
            @change="editProfilePage"
            :disabled="!hasRight('users.manage')"
        />
        <v-text-field
            v-model="activityTime"
            label="Активность сегодня"
            class="evi-text-field mb-8"
            color="#44D370"
            readonly
        ></v-text-field>
        <div class="current-plan mb-11" v-if="profile">Текущий план на месяц:
          <span>{{ profile.monthly_plan | VMask(currencyMask) }}</span></div>
      </div>
      <div class="user-page__subdivisions">
        <div class="user-page__headmaster mb-11" v-if="user.head && !user.is_main">
          <p>Руководитель</p>
          <v-menu
              top
              offset-y
              max-height="156"
              open-on-hover
          >
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                  color="#FFFFFF"
                  size="53"
                  class="user-page__avatar"
                  @click="openUserPage(user.head.id)"
                  v-bind="attrs" v-on="on"
              >
                <img
                    :src="user.head.avatar_url || require('@/assets/img/users/profile-avatar.png')"
                    alt=""
                >
              </v-avatar>
            </template>
            <v-list-item>{{ user.head.name }}</v-list-item>
          </v-menu>
        </div>
        <div class="user-page__subordinates" v-if="user.subordinates && user.subordinates.length">
          <p>Подчиненные</p>
          <div class="items">
            <v-menu
                top
                offset-y
                max-height="156"
                open-on-hover
                v-for="subuser in user.subordinates"
                :key="subuser.id"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                    color="#FFFFFF"
                    size="53"
                    class="user-page__avatar"
                    @click="openUserPage(subuser.id)"
                    v-bind="attrs" v-on="on"
                >
                  <img
                      :src="subuser.avatar_url || require('@/assets/img/users/profile-avatar.png')"
                      alt=""
                  >
                </v-avatar>
              </template>
              <v-list-item>{{ subuser.name }}</v-list-item>
            </v-menu>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="passwordChangeModal" width="700">
      <ChangePasswordDialog @close="passwordChangeModal = false"/>
    </v-dialog>
    <v-dialog v-model="mainUserChangeModal" width="700">
      <ChangeMainUser @close="mainUserChangeModal = false" @update="getData"/>
    </v-dialog>
    <v-dialog v-model="avatarChangeModal" width="700">
      <v-card class="modal-create">
        <v-form ref="form">
          <v-card-title class="d-flex justify-center">
            <span class="text-h5 text-center mb-11">Загрузить аватар</span>
          </v-card-title>
          <v-card-text class="modal-create__form">
            <div class="close-dialog" @click="avatarChangeModal = false">
              <img :src="require('@/assets/img/common/close-dialog.svg')"/>
            </div>
            <v-col
                cols="12"
            >
              <v-file-input
                  accept="image/*"
                  show-size
                  label="Выберите файл"
                  class="evi-text-field"
                  v-model="avatar"
              />
<!--              <small class="ml-8">Рекомендуемое разрешение: 512х512</small>-->
<!--              <br>-->
              <small class="ml-8">Размер файла не должен превышать 5 мб</small>
            </v-col>
          </v-card-text>
          <v-card-actions class="modal-create__actions">
            <v-btn class="evi-button-green evi-button-green--fill" @click="editProfilePage">
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {OpenPagesMixin} from "@/mixins/OpenPagesMixin";
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import {hasRight} from '@/utils/access/hasRight';
import {SubdivisionAutocompleteRequestsMixin} from "@/mixins/autocomplete/SubdivisionAutocompleteRequestsMixin";
import ChangePasswordDialog from "../../components/profile/ChangePasswordDialog";
import {pluralize} from "../../tools/Helpers";
import ChangeMainUser from "@/components/profile/ChangeMainUser";

const currencyMask = createNumberMask({
  prefix: '',
  allowDecimal: true,
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ' ',
  allowNegative: true,
});

export default {
  name: "Profile",
  components: {ChangeMainUser, ChangePasswordDialog},
  mixins: [OpenPagesMixin, SubdivisionAutocompleteRequestsMixin],
  data: () => ({
    subdivision: null,
    role: null,
    position: '',
    email: '',
    name: '',
    avatar: null,
    avatarChangeModal: false,
    passwordChangeModal: false,
    mainUserChangeModal: false,
    currencyMask: currencyMask,
  }),
  computed: {
    ...mapState("users", ["user", 'subdivisions']),
    ...mapState("permissions", ["roles",]),
    ...mapState("user", ["profile"]),
    ...mapState("common", ["loading"]),
    localSubdivisions() {
      if (this.subdivisions.length) {
        return this.subdivisions;
      } else if (this.profile.subdivision) {
        return [this.profile.subdivision];
      }
      return [];
    },
    localRoles() {
      if (this.roles.length) {
        return this.roles;
      } else if (this.profile.roles) {
        return this.profile.roles;
      }
      return [];
    },
    activityTime() {
      return this.returnTimeUserActivity(this.profile.today_activity_time);
    },
  },
  methods: {
    ...mapActions({
      editUser: 'users/editUser',
      getUser: 'users/getUser',
      getRoles: 'permissions/getRoles',
      getPermissions: 'permissions/getPermissions',
      logOut: 'user/logOut',
      getProfile: 'user/getProfile',
      editProfile: 'user/editProfile',
      editProfileAvatar: 'user/editProfileAvatar',
    }),
    returnTimeUserActivity(time) {
      let hours = Math.floor(time / 60);
      let minutes = Math.floor((time / 60 - hours) * 60);

      let minutesPluralize = ['минута', 'минуты', 'минут'];
      let hoursPluralize = ['час', 'часа', 'часов'];

      if (time > 60) {
        return pluralize(hours, hoursPluralize) + ' ' + pluralize(minutes, minutesPluralize);
      } else {
        return pluralize(time, minutesPluralize);
      }
    },
    hasRight: hasRight,
    editUserPage() {
      this.editUser({
        email: this.email,
        subdivision_id: this.subdivision,
        role_id: this.role,
        position: this.position || '',
        id: this.profile.id,
      });
    },
    editProfilePage() {
      if (this.avatar) {
        let formData = new FormData();
        formData.append('avatar', this.avatar);
        formData.append('name', this.name);
        formData.append('email', this.email);
        formData.append('_method', 'PUT');
        this.editProfileAvatar(formData)
            .then(() => {
              this.avatarChangeModal = false;
            });
      } else {
        this.editProfile({
          email: this.email,
          name: this.name,
        });
      }
    },
    getData() {
      this.avatar = null;
      this.getProfile().then((response) => {
        this.getUser(response.id)
      });
    },
  },
  mounted() {
    this.getData();
    this.getRoles();
  },
  watch: {
    profile(val) {
      if (val) {
        this.name = val.name;
        this.email = val.email;
        this.subdivision = val.subdivision.id;
        this.role = val.roles[0].id;
        this.position = val.position;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.user-page {
  background: #FFFFFF;
  border-radius: 32px;
  min-height: 751px;

  &__header {
    width: 100%;
    padding: 56px 94px;
    display: flex;
    justify-content: space-between;
    background: #FFFFFF;
    box-shadow: 0 24px 48px rgba(30, 28, 46, 0.08);
    border-radius: 32px;
  }

  &__close {
    cursor: pointer;
    border: 2px solid rgba(0, 0, 0, 0.38);
    border-radius: 50%;
    height: 35px;
    width: 35px;
    position: absolute;
    top: 30px;
    right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 11px;
      /*position: absolute;*/
      top: 10px;
      left: 10px;
    }
  }

  &__name {
    display: flex;
    position: relative;

    .evi-text-field {
      width: 362px;
      max-width: 362px;
      margin-left: 24px;
      margin-top: 16px;
    }

    .users__status {
      position: absolute;
      top: 80px;
      left: 90px;

      img {
        width: 23px;
      }
    }
  }

  &__body {
    padding: 0 100px;
    margin-top: 54px;
    display: flex;
  }

  &__positions {
    max-width: 262px;
    margin-right: 95px;
    padding-top: 40px;
  }

  &__roles-select {
    display: flex;
    font-weight: 600 !important;
    font-size: 14px !important;

    span {
      display: block;
      content: '';
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background-color: #99AAB5;
      margin-right: 7px;
    }
  }

  .v-select__selection--comma {
    display: none !important;
  }

  &__subdivisions {
    border-left: 1px solid #EBEBEC;
    padding-left: 97px;
    padding-right: 25px;
    padding-top: 40px;

    p {
      font-weight: normal;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.38) !important;;
    }
  }

  &__headmaster {

    .user-page__avatar {
      cursor: pointer;
    }
  }

  &__subordinates {
    .items {
      max-width: 330px;

      .user-page__avatar {
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
  }

}
</style>
