<template>
  <v-card class="modal-create">
    <v-form ref="form" v-model="valid">
      <v-card-title class="d-flex justify-center">
        <span class="text-h5 text-center mb-11">Смена владельца</span>
      </v-card-title>
      <v-card-text class="modal-create__form">
        <div class="close-dialog" @click="closeDialog">
          <img :src="require('@/assets/img/common/close-dialog.svg')"/>
        </div>

        <v-col
            cols="12"
        >
          <v-autocomplete
              class="evi-autocomplete"
              color="#44D370"
              v-model="user"
              :items="users"
              item-text="name"
              item-value="id"
              placeholder="Выберите нового владельца"
              :rules="userRules"
              :loading="userLoading"
              @focus="loadUsers"
              :search-input.sync="userSearch"
              @input.native="searchUser(userSearch)"
              clearable
              item-color="green"
          >
            <template v-slot:label>
              Владелец
              <span class="important">*</span>
            </template>
            <template v-slot:append-item>
              <div v-intersect="userIntersect" />
            </template>
            <template v-slot:item="{item}">
              <v-avatar
                  color="#FFFFFF"
                  size="30"
                  class="user-page__avatar mr-3"
              >
                <img
                    :src="item.avatar_url ||require('@/assets/img/users/profile-avatar.png')"
                    alt=""
                >
              </v-avatar>
              <div class="v-list-item__title">{{item.name}}</div>
            </template>
            <template v-slot:append>
              <span class="evi-select__arrow">
                  <v-icon>mdi-chevron-down</v-icon>
              </span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
            cols="12"
        >
          <v-text-field
              v-model="oldPassword"
              :append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showOldPassword ? 'text' : 'password'"
              label="Пароль"
              placeholder="Введите пароль"
              @click:append="showOldPassword = !showOldPassword"
              class="evi-text-field"
              color="#44D370"
              :rules="passwordRules"
          />
        </v-col>
      </v-card-text>
      <v-card-actions class="modal-create__actions">
        <v-btn class="evi-button-green evi-button-green--fill" @click="change">
          Передать права владельца
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import {mapActions} from "vuex";
import {UserAutocompleteRequestsMixin} from "@/mixins/autocomplete/UserAutocompleteRequestsMixin";

export default {
  name: "ChangeMainUser",
  mixins: [UserAutocompleteRequestsMixin],
  data() {
    return {
      oldPassword: '',
      user: null,
      showOldPassword: false,
      valid: true,
      userRules: [(v) => !!v || 'Выберите пользователя'],
      passwordRules: [(v) => !!v || 'Введите пароль'],
    }
  },
  methods: {
    ...mapActions({
      changeMainUser: 'user/changeMainUser',
    }),
    validate() {
      return !!this.$refs.form.validate();
    },
    change() {
      if (this.validate()) {
        this.changeMainUser({
          "current_password": this.oldPassword,
          "user_id": this.user,
        }).then((response) => {
          if (response) {
            this.$emit('update');
            this.closeDialog();
          }
        });
      }
    },
    closeDialog() {
      this.$emit('close');
      this.oldPassword = '';
      this.user = null;
      this.$refs.form.resetValidation();
    },
  },
}
</script>

<style scoped>

</style>
